@import '../node_modules/antd/dist/antd.css';

p {
  letter-spacing: 0.0625rem;
  margin-bottom: 0.25rem !important;
}

.header.ant-layout-header {
  background-color: black !important;
}

.nav.ant-menu-dark {
  background-color: black !important;
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 50% !important;
  border: 2px solid #d9d9d9 !important;
}

.ant-btn-sm {
  font-size: 14px !important;
}

.ant-upload > img {
  border-radius: 50%;
}
.ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0 !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 1px !important;
}

.HeroBgUpload-uploader {
  background-color: blue;
}

.HeroBgUpload-uploader > .ant-upload.ant-upload-select-picture-card {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  border-radius: 0 !important;
  height: 25vh;
}

.ant-table-column--show {
  display: table-cell;
}

.ant-table-column--hide {
  display: none;
}
